<template>
  <div class="my-4">
    <div class="form-check form-check-inline">
      <input type="radio" id="radio-1" class="form-check-input" value="disponible" v-model.trim="hotspot.estado">
      <label for="radio-1" class="form-check-label">Disponible</label>
    </div>

    <div class="form-check form-check-inline">
      <input type="radio" id="radio-2" class="form-check-input" value="vendido" v-model="hotspot.estado">
      <label for="radio-2" class="form-check-label">Vendido</label>
    </div>

    <div class="form-check form-check-inline">
      <input type="radio" id="radio-3" class="form-check-input" value="reservado" v-model="hotspot.estado">
      <label for="radio-3" class="form-check-label">Reservado</label>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6 col-6 mb-2" v-if="hotspot.izquierda != -1">
      <label for="formIzquierda" class="form-label mx-2">Izquierda</label>
      <input type="text" pattern="^\d{0,3}(\.\d{1,3})?" :title=this.maxValueAllowed
        class="form-control mb-2 btn-square" id="formIzquierda" placeholder="" v-model.trim="hotspot.izquierda">
    </div>
    <div class="col-xs-6 col-6 mb-2" v-if="hotspot.derecha != -1">
      <label for="formDerecha" class="form-label mx-2">Derecha</label>
      <input type="text" pattern="^\d{0,3}(\.\d{1,3})?" :title=this.maxValueAllowed
        class="form-control mb-2 btn-square" id="formDerecha" placeholder="" v-model.trim="hotspot.derecha">
    </div>
    <div class="col-xs-6 col-6 mb-2" v-if="hotspot.fondo != -1">
      <label for="formFondo" class="form-label mx-2">Fondo</label>
      <input type="text" pattern="^\d{0,3}(\.\d{1,3})?" :title=this.maxValueAllowed
        class="form-control mb-2 btn-square" id="formFondo" placeholder="" v-model.trim="hotspot.fondo">
    </div>
    <div class="col-xs-6 col-6 mb-2" v-if="hotspot.frente != -1">
      <label for="formFrente" class="form-label mx-2">Frente</label>
      <input type="text" pattern="^\d{0,3}(\.\d{1,3})?" :title=this.maxValueAllowed
        class="form-control mb-2 btn-square" id="formFrente" placeholder="" v-model.trim="hotspot.frente">
    </div>
  </div>
  <div class="row">
    <div class="mt-2 col-xs-6 col-6" v-if="hotspot.area != -1">
      <label for="formArea" class="form-label mt-2 mx-2">Área</label>
      <input type="text" pattern="^\d{0,4}(\.\d{1,3})?" title=this.maxValueAllowed id="formArea"
        class="form-control btn-square" v-model.number="hotspot.area" :disabled="bloquearInput">
    </div>
    <div class="mt-2 col-xs-6 col-6" v-if="hotspot.perimetro != -1">
      <label for="formArea" class="form-label mt-2 mx-2">Perímetro</label>
      <input type="text" id="formArea" class="form-control btn-square" v-model.number="this.calcultePerimeter" disabled>
    </div>
  </div>

  <div class="alert alert-danger mt-4" v-if="validation.tipo !== null">
    {{validation.mensaje}}
  </div>

  <div class="d-grid gap-2">
    <button class="btn btn-primary mt-4 btn-block btn-square" type="submit" :disabled="bloquear">
      Guardar
    </button>
  </div>
  <div class="d-grid gap-2">
      <router-link class="btn btn-secondary mt-4 ml-2 btn-bloc btn-square" :to="{
                        name: 'Home'}">
                        Volver
    </router-link>
    </div>
</template>

<script>
export default {
  data: () => {
    return {
      validation: { tipo: null, mensaje: null },
      errorMessage: "Todos los campos son obligatorios y deben ser diferentes de cero.",
      perimeter: 0,
      newPerimeter: null,
      maxValueAllowed: "999.999 es el valor máximo permitido",
    }

  },
  props: {
    hotspot: Object,

  },
  created() {
    let izquierda = this.hotspot.izquierda == -1 ? 0 : this.hotspot.izquierda;
    let derecha = this.hotspot.derecha == -1 ? 0 : this.hotspot.derecha;
    let fondo = this.hotspot.fondo == -1 ? 0 : this.hotspot.fondo;
    let frente = this.hotspot.frente == -1 ? 0 : this.hotspot.frente;
    this.perimeter = izquierda + derecha + fondo + frente;
    this.newPerimeter = this.perimeter;
  },
  computed: {
    bloquear() {
      if (this.hotspot.izquierda == null || this.hotspot.izquierda == "") {
        this.validation = { tipo: "medidas", mensaje: this.errorMessage };
        return true;
      }
      if (this.hotspot.derecha == null || this.hotspot.derecha == "") {
        this.validation = { tipo: "medidas", mensaje: this.errorMessage };
        return true;
      }
      if (this.hotspot.fondo == null || this.hotspot.fondo == "") {
        this.validation = { tipo: "medidas", mensaje: this.errorMessage };
        return true;
      }
      if (this.hotspot.frente == null || this.hotspot.frente == "") {
        this.validation = { tipo: "medidas", mensaje: this.errorMessage };
        return true;
      }
      if (this.hotspot.area == null || this.hotspot.area == "") {
        this.validation = { tipo: "medidas", mensaje: this.errorMessage };
        return true;
      }

      this.validation = { tipo: null, mensaje: null };

      return false;
    },
    bloquearInput() {
      return false
    },
    
    calcultePerimeter() {
      let izquierda = this.hotspot.izquierda == -1 ? 0 : (this.hotspot.izquierda) / 1;
      let derecha = this.hotspot.derecha == -1 ? 0 : this.hotspot.derecha / 1;
      let fondo = this.hotspot.fondo == -1 ? 0 : this.hotspot.fondo / 1;
      let frente = this.hotspot.frente == -1 ? 0 : this.hotspot.frente / 1;
      this.newPerimeter = Math.round(((izquierda + derecha + fondo + frente) / 1) * 100) / 100;
      
      return this.newPerimeter;
    },
    // TODO: Mostrar mensaje indicando que hay cambios por guardar.
    hasNewPerimeter() {
      if (this.newPerimeter != this.perimeter) {
        return 'No olvide guardar los cambios';
      }

      return '';
    }


  }
}
</script>


